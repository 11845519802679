var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-ignore-next-line
import { Text } from "react-native";
import { useState, useEffect, useContext, } from "react";
import { Flex, Padding, StyledText, Typewriter, } from "../Components";
import { ThemeContext } from "../Theme";
import { CAT_MODE_KEY } from "./HomeScreen";
import { isScreenSmall } from "../Helpers";
export var CAT_CAPTION = 10;
var CAT_CLARIFICATION_DELAY = 60 * 1000;
// This component is used to display the home screen messages. These are typed onto the screen one at a time,
// changing to the next one after a short delay. Eventually, the final message will cause the homescreen to
// switch to showing a cat-themed product
export var HomeScreenMessage = function (_a) {
    var setCatMode = _a.setCatMode, catMode = _a.catMode;
    var theme = useContext(ThemeContext);
    // lookup if we're already in cat mode from previous sessions
    var _b = useState(catMode ? CAT_CAPTION : 0), curCaption = _b[0], setCurCaption = _b[1];
    var _c = useState(catMode), deleteTitle = _c[0], setDeleteTitle = _c[1];
    var _d = useState(false), changeTitle = _d[0], setChangeTitle = _d[1];
    var titleFinish = deleteTitle
        ? function () {
            setCatMode(true);
            setChangeTitle(true);
        }
        : undefined;
    var nextCaption = function () { return setCurCaption(curCaption + 1); };
    // when we change to cat mode, change the title and pictures.
    // also setup a 20sec timer to show the extended cat caption
    var changeProduct = function () {
        setDeleteTitle(true);
        setCurCaption(curCaption + 1);
        setTimeout(function () {
            setCurCaption(function (cur) { return cur + 1; });
        }, CAT_CLARIFICATION_DELAY);
    };
    // if we start in cat mode, setup a 20sec timer to show the extended cat caption
    useEffect(function () {
        if (catMode) {
            setTimeout(function () {
                setCurCaption(curCaption + 1);
            }, CAT_CLARIFICATION_DELAY);
        }
    }, []);
    // we use a placeholder to make sure the view remains the same size as the typewriter text even when its empty
    var placeholder = function () { return _jsx(StyledText, __assign({ type: "header" }, { children: "\u200A" })); };
    return (_jsxs(_Fragment, { children: [_jsxs(Flex, __assign({ row: true }, { children: [placeholder(), !changeTitle && (_jsx(Typewriter, __assign({ type: "header", startFull: true, deleteAfter: deleteTitle, onFinish: titleFinish }, { children: "LaPlante Apps" }))), changeTitle && _jsx(Typewriter, __assign({ type: "header" }, { children: "LaCat Apps :3" }))] })), _jsx(Padding, { vertical: 15 }), _jsxs(Flex, __assign({ row: true, style: { height: theme.messageHeightHolder } }, { children: [placeholder(), getCaption(curCaption, nextCaption, changeProduct)] }))] }));
};
// this allows us to step through our list of captions simply
export var getCaption = function (curCaption, nextCaption, changeProduct) {
    var smallScreen = isScreenSmall();
    var style = {
        textAlign: smallScreen ? "center" : undefined,
    };
    var counter = 0;
    // all captions share the same default props, but some are overriden to change speed, etc for certain captions
    var defaultProps = function () {
        counter++;
        return {
            key: counter,
            deleteAfter: true,
            speed: 50,
            deleteSpeed: 20,
            pauseTime: 500,
            onFinish: nextCaption,
            type: "caption",
            style: style,
        };
    };
    return [
        _jsx(Typewriter, __assign({}, defaultProps(), { pauseTime: 1000 }, { children: "This is a caption that I'll make informative and interesting and everything :). Not that you're reading it. Oh, you are? Ok, here we go!" })),
        // We use hairs here for consistent spacing and speed
        _jsxs(Typewriter, __assign({}, defaultProps(), { pauseTime: 1000 }, { children: ["This site is dedicated to LaPlante Apps (well, I guess that's obvious), a little business that makes mobile apps, board games, computer games, and more!", twentyHairs, " . . . Happy?"] })),
        _jsx(Typewriter, __assign({}, defaultProps(), { pauseTime: 1000 }, { children: ". . . You're still here? I mean, it's just a landing page. Not much to see . . ." })),
        _jsx(Typewriter, __assign({}, defaultProps(), { children: "Navigate elsewhere, wierdo." })),
        _jsx(Typewriter, __assign({}, defaultProps(), { children: "FOR REAL NOW" })),
        _jsx(Typewriter, __assign({}, defaultProps(), { children: "I CANT KEEP DOING THIS FOREVER" })),
        _jsx(Typewriter, __assign({}, defaultProps(), { children: "Or can I?" })),
        // Once the user sees the next caption (the meows), we assume they've made it to cat mode on subsequent visits
        _jsx(Typewriter, __assign({}, defaultProps(), { onFinish: function () {
                nextCaption();
                localStorage.setItem(CAT_MODE_KEY, "yes");
            } }, { children: "Jk I can't. But I can refuse to cooperate." })),
        _jsx(Typewriter, __assign({}, defaultProps(), { speed: 25 }, { children: "Meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow meow" })),
        _jsx(Typewriter, __assign({}, defaultProps(), { deleteAfter: false, onFinish: changeProduct }, { children: "I like cats." })),
        // We have a duplicate of the caption here so that we can show the extended caption appropriately on subsequent loads
        _jsx(StyledText, __assign({ type: "caption", style: style }, { children: "I like cats." })),
        _jsxs(Text, __assign({ style: style }, { children: [_jsx(StyledText, __assign({ type: "caption" }, { children: "I like cats." })), _jsx(Typewriter, __assign({}, defaultProps(), { pauseTime: 5000, onFinish: undefined, style: {} }, { children: "    .    .    .    (Actually it would be spelled LeChatte)" }))] })),
    ][curCaption];
};
var twentyHairs = "\u200A\u200A\u200A\u200A\u200A\u200A\u200A\u200A\u200A\u200A\u200A\u200A\u200A\u200A\u200A\u200A\u200A\u200A\u200A\u200A";
