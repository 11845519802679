var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createElement as _createElement } from "react";
import { jsx as _jsx } from "react/jsx-runtime";
import { Text, Animated } from "react-native";
import { useContext, useState, useEffect, useRef, } from "react";
import { ThemeContext } from "../Theme";
import { v4 as uuid } from "uuid";
export var StyledText = function (props) {
    var theme = useContext(ThemeContext);
    // setup style
    var newProps = __assign({}, props);
    var _a = props.style, style = _a === void 0 ? {} : _a, type = props.type, animated = props.animated;
    var themeStyle = {};
    // use the given type to lookup that text type style in our theme
    if (type) {
        var fullType = type === "button" ? "buttonText" : type;
        themeStyle = theme[fullType];
    }
    newProps.style = __assign(__assign(__assign({}, theme.text), themeStyle), style);
    if (animated)
        return _jsx(Animated.Text, __assign({}, newProps));
    // @ts-ignore-next-line - it doesn't know how to handle animated styles with the animated prop
    else
        return _jsx(Text, __assign({}, newProps));
};
export var TWText = function (props) {
    return _jsx(StyledText, __assign({}, props));
};
export var Typewriter = function (props) {
    var children = props.children, _a = props.deleteAfter, deleteAfter = _a === void 0 ? false : _a, _b = props.speed, speed = _b === void 0 ? 100 : _b, _c = props.deleteSpeed, deleteSpeed = _c === void 0 ? speed : _c, _d = props.startFull, startFull = _d === void 0 ? false : _d, _e = props.pauseTime, pauseTime = _e === void 0 ? 1000 : _e, _f = props.onFinish, onFinish = _f === void 0 ? function () { } : _f;
    // text visible on screen
    var _g = useState([]), text = _g[0], setText = _g[1];
    var _h = useState(0), index = _h[0], setIndex = _h[1];
    var _j = useState(false), isDone = _j[0], setIsDone = _j[1];
    // what our final text will look like, represented as an array of single-charcter TWText components
    var finalText = useRef(parseSubText(children)).current;
    // type characters one at a time
    useEffect(function () {
        // if we are starting with the text fully typed out, skip the animation
        if (startFull) {
            setText(finalText);
            if (deleteAfter) {
                setTimeout(function () {
                    setIsDone(true);
                }, pauseTime);
            }
            else {
                onFinish();
            }
            return;
        }
        // type characters one at a time
        if (index <= finalText.length) {
            setTimeout(function () {
                setText(finalText.slice(0, index));
                setIndex(index + 1);
            }, speed);
        }
        // done typing, wait for a bit and then delete the text
        else if (deleteAfter) {
            setTimeout(function () {
                setIsDone(true);
            }, pauseTime);
        }
        else {
            onFinish();
        }
    }, [index]);
    // delete characters one at a time if deleteAfter is true
    useEffect(function () {
        if (isDone) {
            setTimeout(function () {
                setText(text.slice(0, text.length - 1));
                if (text.length === 0) {
                    setIsDone(false);
                    onFinish();
                }
            }, deleteSpeed);
        }
    }, [isDone, text]);
    // if deleteAfter is changed to true after the text is fully typed out, start deleting it
    useEffect(function () {
        if (deleteAfter && text.length === finalText.length) {
            setIsDone(true);
        }
    }, [deleteAfter]);
    return _jsx(StyledText, __assign({}, props, { children: text }));
};
// turns the children of Typewriter into an array of single-character TWText components
var parseSubText = function (text) {
    if (typeof text === "string") {
        return text.split("").map(function (char) { return _jsx(TWText, { children: char }, uuid()); });
    }
    else if (Array.isArray(text)) {
        return text.map(function (subText) { return parseSubText(subText); }).flat();
    }
    else {
        var slimmedProps_1 = __assign(__assign({}, text.props), { children: undefined });
        return text.props.children.split("").map(function (char) { return (_createElement(TWText, __assign({}, slimmedProps_1, { key: uuid() }), char)); });
    }
};
