var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Animated, Image, Dimensions } from "react-native";
import { useEffect, useRef, useContext } from "react";
import { easeOutBack, CardFlip } from "../Components";
import phone_back from "../assets/phone_back.png";
import phone_front from "../assets/phone_front.png";
import { ThemeContext } from "../Theme";
// This component animates in the phone. It will slide up playfully, then flip over to reveal the front of the phone
// You'll see constants used in various equations below. These are relative sizes to ensure that all the images appear in the correct proportions
export var PlayfulPhone = function (_a) {
    var onAnimationComplete = _a.onAnimationComplete, _b = _a.fast, fast = _b === void 0 ? false : _b;
    // animate in a phone from off-screen
    var theme = useContext(ThemeContext);
    // we dont need a listener since the theme listens for us
    var windowHeight = Dimensions.get("window").height / 2;
    var phoneTop = useRef(new Animated.Value(windowHeight)).current;
    var phoneScale = useRef(new Animated.Value(theme.phoneScaleInitial)).current;
    var cardRef = useRef(null);
    var finalPhoneScale = theme.phoneScaleFinal;
    var basePhoneHeight = theme.phoneHeight;
    var defaultPhoneHeight = 1232;
    var phoneHeightRatio = basePhoneHeight / defaultPhoneHeight;
    var basePhoneWidth = 572 * phoneHeightRatio;
    useEffect(function () {
        // Use various delays to time the animations naturally
        Animated.sequence([
            Animated.delay(1000),
            // slide up (onto the screen) at moderate speed
            Animated.timing(phoneTop, {
                toValue: windowHeight - 500 * phoneHeightRatio,
                duration: 1000,
                useNativeDriver: false,
            }),
            Animated.delay(500),
            // slide back down (off-screen) slowly
            Animated.timing(phoneTop, {
                toValue: windowHeight,
                duration: fast ? 3000 : 4000,
                useNativeDriver: false,
            }),
            Animated.delay(fast ? 400 : 800),
            // slide up (onto the screen) a little bit at high speed
            Animated.timing(phoneTop, {
                toValue: windowHeight - 350 * phoneHeightRatio,
                duration: 500,
                useNativeDriver: false,
            }),
            Animated.delay(100),
            // slide back off also at high speed
            Animated.timing(phoneTop, {
                toValue: windowHeight,
                duration: 300,
                useNativeDriver: false,
            }),
            Animated.delay(fast ? 500 : 1000),
            // now scale the phone down to its normal size and slide it up into the center of its container
            Animated.parallel([
                Animated.timing(phoneTop, {
                    toValue: -basePhoneHeight / 2,
                    easing: easeOutBack,
                    duration: 1000,
                    useNativeDriver: false,
                }),
                Animated.timing(phoneScale, {
                    toValue: finalPhoneScale,
                    duration: 1000,
                    useNativeDriver: false,
                }),
            ]),
            Animated.delay(500),
        ]).start(function () {
            var _a;
            // flip the phone to reveal the front face
            (_a = cardRef.current) === null || _a === void 0 ? void 0 : _a.flip();
        });
    }, []);
    // render the phone
    return (_jsx(Animated.View, __assign({ style: {
            position: "absolute",
            zIndex: 0,
            transform: [{ scale: phoneScale }, { translateY: phoneTop }],
        } }, { children: _jsxs(CardFlip, __assign({ ref: cardRef, expectedWidth: basePhoneWidth, onFlipEnd: function () { return setTimeout(onAnimationComplete, 1500); } }, { children: [_jsx(Image, { style: {
                        width: basePhoneWidth,
                        height: basePhoneHeight,
                    }, source: phone_back }), _jsx(Image, { style: {
                        width: basePhoneWidth,
                        height: basePhoneHeight,
                    }, source: phone_front })] })) })));
};
