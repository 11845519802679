var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { TouchableOpacity, View, Animated } from "react-native";
import React, { useContext, useRef, useEffect } from "react";
import { SetThemeContext, ThemeContext, Themes } from "../Theme";
import { v4 as uuid } from "uuid";
import { isScreenSmall } from "../Helpers";
// we keep this global so that our floating text helper function can easily gain access to updated themes
var themeContainer = undefined;
// These are the three buttons in the header which can be used to change the theme if pressed in the correct order
export var ThemeButtons = function () {
    var smallScreen = isScreenSmall();
    var theme = useContext(ThemeContext);
    useEffect(function () {
        themeContainer = theme;
    }, [theme]);
    var setTheme = useContext(SetThemeContext);
    var _a = React.useState((themeContainer === null || themeContainer === void 0 ? void 0 : themeContainer.name) === "broken"), isBroken = _a[0], setIsBroken = _a[1];
    var _b = React.useState({}), floatingTextOne = _b[0], setFloatingTextOne = _b[1];
    var _c = React.useState({}), floatingTextTwo = _c[0], setFloatingTextTwo = _c[1];
    var _d = React.useState({}), floatingTextThree = _d[0], setFloatingTextThree = _d[1];
    var notAcceptingPressesRightNow = useRef(false);
    // cute math trick to watch the order of the 3 buttons pressed
    // if theyre presed left-to-right, then the theme will change
    var curButtonsVal = useRef(0);
    var onPressOne = function () {
        if (notAcceptingPressesRightNow.current)
            return;
        curButtonsVal.current = 1;
        addFloatingText(setFloatingTextOne, isBroken ? "Please" : "Do");
    };
    var onPressTwo = function () {
        if (notAcceptingPressesRightNow.current)
            return;
        curButtonsVal.current = curButtonsVal.current / 2;
        addFloatingText(setFloatingTextTwo, isBroken ? "Fix" : "Not");
    };
    var onPressThree = function () {
        if (notAcceptingPressesRightNow.current)
            return;
        if (curButtonsVal.current === 0.5) {
            toggleTheme();
        }
        curButtonsVal.current = 0;
        addFloatingText(setFloatingTextThree, isBroken ? "Me" : "Touch");
    };
    var toggleTheme = function () {
        var newTheme = isBroken ? "dark" : "broken";
        setTheme(function () { return Themes[newTheme]; });
        setIsBroken(!isBroken);
        notAcceptingPressesRightNow.current = true;
        var delay = 400;
        addFloatingText(setFloatingTextOne, "Oh", delay);
        delay += 100;
        addFloatingText(setFloatingTextTwo, "My", delay);
        delay += 100;
        addFloatingText(setFloatingTextThree, "God", delay);
        delay += 750;
        addFloatingText(setFloatingTextOne, "You", delay);
        addFloatingText(setFloatingTextTwo, isBroken ? "Fixed" : "Broke", delay);
        delay += 100;
        addFloatingText(setFloatingTextThree, "It", delay);
        delay += 500;
        for (var i = 0; i < 20; i++) {
            addFloatingText(setFloatingTextOne, isBroken ? "Yaaa" : "Aaaa", delay);
            addFloatingText(setFloatingTextTwo, "aaaa", delay);
            addFloatingText(setFloatingTextThree, isBroken ? "aaay" : "aaaa", delay);
            delay += 200;
        }
        delay += 800;
        setTimeout(function () {
            notAcceptingPressesRightNow.current = false;
        }, delay);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(View, __assign({ style: { marginLeft: smallScreen ? "auto" : undefined } }, { children: [_jsx(TouchableOpacity, { style: theme.navButton, onPress: onPressOne }), Object.values(floatingTextOne)] })), _jsxs(View, { children: [_jsx(TouchableOpacity, { style: theme.navButton, onPress: onPressTwo }), Object.values(floatingTextTwo)] }), _jsxs(View, { children: [_jsx(TouchableOpacity, { style: theme.navButton, onPress: onPressThree }), Object.values(floatingTextThree)] })] }));
};
var addFloatingText = function (setFloatingText, text, delay) {
    if (delay === void 0) { delay = 0; }
    setTimeout(function () {
        // get an up-to-date mutable copy of the state so we set it right
        setFloatingText(function (lastState) {
            var textCopy = __assign({}, lastState);
            var id = uuid();
            var anim = new Animated.Value(0);
            // This effectively centers us based on the width and margins of our nav button
            var width = themeContainer
                ? themeContainer.navButton.width +
                    2 * themeContainer.navButton.marginLeft
                : 0;
            // add the text to our list of animations
            textCopy[id] = (_jsx(Animated.View, __assign({ style: {
                    position: "absolute",
                    top: anim.interpolate({
                        inputRange: [0, 1],
                        outputRange: [0, 200],
                    }),
                    opacity: anim.interpolate({
                        inputRange: [0, 1],
                        outputRange: [1, 0],
                    }),
                    width: width,
                    alignItems: "center",
                } }, { children: _jsx(Animated.Text, __assign({ style: themeContainer === null || themeContainer === void 0 ? void 0 : themeContainer.floatingText }, { children: text })) }), id));
            // kick off the animation
            Animated.timing(anim, {
                toValue: 1,
                duration: 1000,
                useNativeDriver: false,
            }).start(function () {
                // remove animation from our list after animation finishes
                // get an up-to-date copy
                setFloatingText(function (lastState2) {
                    var textCopy2 = __assign({}, lastState2);
                    textCopy2[id] = null;
                    return textCopy2;
                });
            });
            // finish setting state
            return textCopy;
        });
    }, delay);
};
