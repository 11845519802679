var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useImperativeHandle, forwardRef, } from "react";
import { StyleSheet, Animated } from "react-native";
// This is a component that flips between two children, animating the flip with a slight depth effect
var CardFlipComponent = function (_a, ref) {
    var _b = _a.style, style = _b === void 0 ? {} : _b, _c = _a.duration, duration = _c === void 0 ? 750 : _c, _d = _a.flipZoom, flipZoom = _d === void 0 ? 0.2 : _d, _e = _a.flipDirection, flipDirection = _e === void 0 ? "y" : _e, _f = _a.onFlip, onFlip = _f === void 0 ? function () { } : _f, _g = _a.onFlipStart, onFlipStart = _g === void 0 ? function () { } : _g, _h = _a.onFlipEnd, onFlipEnd = _h === void 0 ? function () { } : _h, _j = _a.children, children = _j === void 0 ? [] : _j, expectedWidth = _a.expectedWidth;
    var _k = React.useState(0), side = _k[0], setSide = _k[1];
    var progress = React.useRef(new Animated.Value(0)).current;
    var rotationX = React.useRef(new Animated.Value(50)).current;
    var rotationY = React.useRef(new Animated.Value(50)).current;
    var zoom = React.useRef(new Animated.Value(0)).current;
    var _l = React.useState(""), rotateOrientation = _l[0], setRotateOrientation = _l[1];
    // Expose flip method to parent & call helper based on direction
    useImperativeHandle(ref, function () { return ({
        flip: function () {
            if (flipDirection == "y") {
                flipY();
            }
            else {
                flipX();
            }
        },
    }); });
    // flipping over y axis
    function flipY() {
        _flipTo({
            x: 50,
            y: side === 0 ? 100 : 50,
        });
        // update state for later flips
        setSide(side === 0 ? 1 : 0);
        setRotateOrientation("y");
    }
    // flipping over x axis
    function flipX() {
        _flipTo({
            y: 50,
            x: side === 0 ? 100 : 50,
        });
        // update state for later flips
        setSide(side === 0 ? 1 : 0);
        setRotateOrientation("x");
    }
    function _flipTo(toValue) {
        // callbacks
        onFlip === null || onFlip === void 0 ? void 0 : onFlip(side === 0 ? 1 : 0);
        onFlipStart === null || onFlipStart === void 0 ? void 0 : onFlipStart(side === 0 ? 1 : 0);
        Animated.parallel([
            // use a central progress value to interpolate the opacity of both sides simultaneously
            Animated.timing(progress, {
                toValue: side === 0 ? 100 : 0,
                duration: duration,
                useNativeDriver: false,
            }),
            // zoom in and then out (scale up and then down) in for depth effect
            Animated.sequence([
                Animated.timing(zoom, {
                    toValue: 100,
                    duration: duration / 2,
                    useNativeDriver: false,
                }),
                Animated.timing(zoom, {
                    toValue: 0,
                    duration: duration / 2,
                    useNativeDriver: false,
                }),
            ]),
            // rotate around the given axis to make the side look like its flipping over
            Animated.timing(rotationX, {
                toValue: toValue.x,
                duration: duration,
                useNativeDriver: false,
            }),
            Animated.timing(rotationY, {
                toValue: toValue.y,
                duration: duration,
                useNativeDriver: false,
            }),
        ]).start(function () {
            // callback
            onFlipEnd === null || onFlipEnd === void 0 ? void 0 : onFlipEnd(side === 0 ? 1 : 0);
        });
    }
    // interpolate side A opacity so it switches over from A to B at 50% flip progress
    var sideAOpacity = progress.interpolate({
        inputRange: [50, 51],
        outputRange: [100, 0],
        extrapolate: "clamp",
    });
    var cardATransform = {
        opacity: sideAOpacity,
        zIndex: side === 0 ? 1 : 0,
        transform: [],
    };
    if (rotateOrientation === "x") {
        // interpolate side A rotation so it flips over the x axis
        var aXRotation = rotationX.interpolate({
            inputRange: [0, 50, 100, 150],
            outputRange: ["-180deg", "0deg", "180deg", "0deg"],
            extrapolate: "clamp",
        });
        cardATransform.transform.push({ rotateX: aXRotation });
    }
    else {
        // interpolate side A rotation so it flips over the Y axis
        var aYRotation = rotationY.interpolate({
            inputRange: [0, 50, 100, 150],
            outputRange: ["-180deg", "0deg", "180deg", "0deg"],
            extrapolate: "clamp",
        });
        cardATransform.transform.push({ rotateY: aYRotation });
    }
    // include a shift along the X axis to counter-act the card sliding out of place as it flips
    cardATransform.transform.push({ translateX: -expectedWidth / 2 });
    // interpolate side B opacity so it switches over from B to A at 50% flip progress
    var sideBOpacity = progress.interpolate({
        inputRange: [50, 51],
        outputRange: [0, 100],
        extrapolate: "clamp",
    });
    var cardBTransform = {
        opacity: sideBOpacity,
        zIndex: side === 0 ? 0 : 1,
        transform: [],
    };
    var bYRotation;
    if (rotateOrientation === "x") {
        // interpolate side B rotation so it flips over the x axis
        var bXRotation = rotationX.interpolate({
            inputRange: [0, 50, 100, 150],
            outputRange: ["0deg", "-180deg", "-360deg", "180deg"],
            extrapolate: "clamp",
        });
        cardBTransform.transform.push({ rotateX: bXRotation });
    }
    else {
        // interpolate side B rotation so it flips over the y axis
        bYRotation = rotationY.interpolate({
            inputRange: [0, 50, 100, 150],
            outputRange: ["0deg", "-180deg", "0deg", "180deg"],
            extrapolate: "clamp",
        });
        cardBTransform.transform.push({ rotateY: bYRotation });
    }
    // include a shift along the X axis to counter-act the card sliding out of place as it flips
    cardBTransform.transform.push({ translateX: -expectedWidth / 2 });
    // scale card with zoom for depth effect
    var cardZoom = zoom.interpolate({
        inputRange: [0, 100],
        outputRange: [1, 1 + flipZoom],
        extrapolate: "clamp",
    });
    var scaling = {
        transform: [{ scale: cardZoom }],
    };
    return (_jsxs(Animated.View, __assign({ style: [style, scaling] }, { children: [_jsx(Animated.View, __assign({ style: [styles.cardContainer, cardATransform] }, { children: children[0] })), _jsx(Animated.View, __assign({ style: [styles.cardContainer, cardBTransform] }, { children: children[1] }))] })));
};
var styles = StyleSheet.create({
    cardContainer: {
        flex: 1,
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
    },
});
// @ts-ignore-next-line
export var CardFlip = forwardRef(CardFlipComponent);
