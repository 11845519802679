var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-ignore-next-line
import { ScrollView, Dimensions, Animated, ActivityIndicator, Linking } from 'react-native';
import { AnimatedScreen } from "./AnimatedScreen";
import { useContext, useEffect, useRef, useState } from "react";
import { FadeInImage, Flex, StyledText } from "../Components";
import { ThemeContext } from "../Theme";
import { isScreenSmall } from "../Helpers";
import sellsheet from "../assets/sellsheet.png";
// It animates in and out using the usual AnimatedScreen
export var KKGMScreen = function (_a) {
    var _b;
    var route = _a.route;
    var theme = useContext(ThemeContext);
    // we do some custom sizing for both the waitlist widget and the sell sheet image
    // these are crucial components so they need to look good on all screens
    // we specifically don't resize them if the window changes because the user may zoom in
    var smallScreen = isScreenSmall();
    var width = Dimensions.get("window").width;
    var resizeSellSheet = width / 3000;
    var resizeBorderRadius = 20;
    var resizeWaitlist = 600;
    if (smallScreen) {
        resizeWaitlist = width;
        resizeSellSheet = width / (1588 + 40);
        resizeBorderRadius = 0;
    }
    var finalSellSheet = useRef(resizeSellSheet).current;
    var finalBorderRadius = useRef(resizeBorderRadius).current;
    var finalWaitlist = useRef(resizeWaitlist).current;
    // the waitlist widget has a weird white loading screen, so we make a custom loading screen for it
    var fadeinWidget = useRef(new Animated.Value(0)).current;
    var fadeoutLoaders = useRef(new Animated.Value(1)).current;
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState([]), activityIndicators = _d[0], setActivityIndicators = _d[1];
    useEffect(function () {
        // every 0.05 seconds, add an activity indicator to the loading screen. this will make a 
        // progress bar of loading indicators
        var interval = setInterval(function () {
            setActivityIndicators((function (existingIndicators) { return __spreadArray(__spreadArray([], existingIndicators, true), [(_jsx(ActivityIndicator, {}))], false); }));
        }, 50);
        // after 2 seconds of this, fade-in the waitlist widget and fade-out the loading indicators
        setTimeout(function () {
            clearInterval(interval);
            Animated.timing(fadeinWidget, {
                toValue: 1,
                duration: 500,
                useNativeDriver: false,
            }).start();
            Animated.timing(fadeoutLoaders, {
                toValue: 0,
                duration: 250,
                useNativeDriver: false,
            }).start(function () {
                setLoading(false);
            });
        }, 2000);
    }, []);
    return (_jsx(AnimatedScreen, __assign({ fadeOut: (_b = route === null || route === void 0 ? void 0 : route.params) === null || _b === void 0 ? void 0 : _b.fadeOut }, { children: _jsx(ScrollView, __assign({ style: { height: 1 } }, { children: _jsxs(Flex, __assign({ fullWidth: true, centered: true }, { children: [_jsx(StyledText, __assign({ type: "body", style: { marginBottom: 50, textAlign: 'center' }, onPress: function () { return Linking.openURL("https://boardgamegeek.com/boardgame/398730/lorecraft"); } }, { children: "See on Board Game Geek, click here!" })), _jsx(FadeInImage, { spinner: true, source: sellsheet, style: {
                            width: 1588 * finalSellSheet,
                            height: 2244 * finalSellSheet,
                            resizeMode: "stretch",
                            borderRadius: finalBorderRadius,
                            marginBottom: 75,
                        } }), _jsx(Flex, __assign({ style: { marginBottom: 75 } }, { children: _jsx("iframe", { width: "560", height: "315", src: "https://www.youtube.com/embed/OjyGHSUeTyg", title: "YouTube video player", frameBorder: "0", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share", allowFullScreen: true }) })), _jsx(Flex, __assign({ style: { marginBottom: 75 } }, { children: _jsx("iframe", { width: "560", height: "315", src: "https://www.youtube.com/embed/19Ma2sum7aQ", title: "YouTube video player", frameBorder: "0", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share", allowFullScreen: true }) })), _jsx(StyledText, __assign({ type: "body", style: { marginBottom: 50, textAlign: 'center' } }, { children: "Standard Play Tutorial Coming Soon!" }))] })) })) })));
};
