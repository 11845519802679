var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Animated, ActivityIndicator, View } from "react-native";
import React from "react";
export var FadeInImage = function (props) {
    // fade in after load
    var opacityObj = React.useRef({ opacity: new Animated.Value(0) }).current;
    var opacitySpinner = React.useRef(new Animated.Value(props.spinner ? 1 : 0)).current;
    // merge animated opacity style into props
    var newStyle;
    if (props.style) {
        if (props.style.constructor === Array) {
            newStyle = __spreadArray(__spreadArray([], props.style, true), [opacityObj], false);
        }
        else {
            newStyle = [props.style, opacityObj];
        }
    }
    else {
        newStyle = opacityObj;
    }
    return (_jsxs(View, { children: [_jsx(Animated.View, __assign({ style: [
                    props.style,
                    {
                        position: "absolute",
                        justifyContent: "center",
                        alignItems: "center",
                        opacity: opacitySpinner,
                    },
                ] }, { children: _jsx(ActivityIndicator, { color: "#AAAAAA" }) })), _jsx(Animated.Image, __assign({}, props, { style: newStyle, onLoad: function (arg) {
                    var _a;
                    (_a = props.onLoad) === null || _a === void 0 ? void 0 : _a.call(props, arg);
                    Animated.timing(opacitySpinner, {
                        toValue: 0,
                        duration: 200,
                        useNativeDriver: false,
                    }).start();
                    Animated.timing(opacityObj.opacity, {
                        toValue: 1,
                        duration: 350,
                        useNativeDriver: false,
                    }).start();
                } }))] }));
};
