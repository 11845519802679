var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { View } from "react-native";
import { styles } from "../Theme";
export var Flex = function (_a) {
    var _b = _a.full, full = _b === void 0 ? false : _b, _c = _a.fullWidth, fullWidth = _c === void 0 ? false : _c, _d = _a.centered, centered = _d === void 0 ? false : _d, _e = _a.slim, slim = _e === void 0 ? false : _e, _f = _a.centeredVertical, centeredVertical = _f === void 0 ? false : _f, _g = _a.style, style = _g === void 0 ? {} : _g, _h = _a.row, row = _h === void 0 ? false : _h, _j = _a.reverse, reverse = _j === void 0 ? false : _j, children = _a.children;
    return (_jsx(View, __assign({ style: [
            full ? styles.flex : {},
            fullWidth ? styles.fullWidth : {},
            centered ? styles.centered : {},
            centeredVertical && row ? styles.alignCenter : {},
            centeredVertical && !row ? styles.justifyCenter : {},
            slim ? styles.slim : {},
            row ? styles.row : {},
            reverse && row ? styles.reverseRow : {},
            reverse && !row ? styles.reverseColumn : {},
            style,
        ] }, { children: children })));
};
export var Padding = function (_a) {
    var _b = _a.vertical, vertical = _b === void 0 ? 0 : _b, _c = _a.horizontal, horizontal = _c === void 0 ? 0 : _c;
    return (_jsx(View, { style: {
            paddingTop: vertical,
            paddingLeft: horizontal,
        } }));
};
