var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TouchableOpacity, Animated, Easing, Dimensions } from "react-native";
import React, { useContext, useRef, forwardRef, useImperativeHandle, } from "react";
import { ThemeContext } from "../Theme";
import { Padding, StyledText } from ".";
// Component that can be toggled by its parent to show/hide a navigation menu
var SideMenuComponent = function (_a, ref) {
    var navigation = _a.navigation;
    var theme = useContext(ThemeContext);
    var sideMenuWidth = theme.sideMenuWidth;
    var sideMenuSpeed = theme.sideMenuSpeed;
    // we don't need to add a listener since the theme will rerender us if it changes
    var smallScreen = Dimensions.get("window").width < 1750;
    // slide in the menu from off-screen left
    var sideMenuLeft = useRef(new Animated.Value(-sideMenuWidth)).current;
    var sideMenuOpacity = useRef(new Animated.Value(1)).current;
    var isSideMenuShown = useRef(false);
    var toggleMenu = function (fast) {
        Animated.timing(sideMenuLeft, {
            toValue: isSideMenuShown.current ? -sideMenuWidth : 0,
            duration: fast ? sideMenuSpeed / 2 : sideMenuSpeed,
            easing: Easing.out(Easing.sin),
            useNativeDriver: false,
        }).start(function () {
            isSideMenuShown.current = !isSideMenuShown.current;
        });
    };
    // when navigating to a new screen, fade out the menu and slide it off-screen
    // Also set page params to trigger the AnimatedScreen component's fade-out animation
    var navigate = function (path) { return function () {
        navigation.setParams({ fadeOut: true });
        toggleMenu(true);
        Animated.timing(sideMenuOpacity, {
            toValue: 0,
            duration: sideMenuSpeed / 2,
            useNativeDriver: false,
        }).start(function () {
            navigation.push(path);
            setTimeout(function () {
                // after completing the navigation, re-opacify the menu
                sideMenuOpacity.setValue(1);
            }, 100);
        });
    }; };
    // Let our parent toggle the menu
    useImperativeHandle(ref, function () { return ({
        toggleMenu: toggleMenu,
    }); });
    // reset menu when theme changes
    React.useEffect(function () {
        sideMenuLeft.setValue(-sideMenuWidth);
        isSideMenuShown.current = false;
    }, [theme]);
    return (_jsxs(Animated.View, __assign({ style: {
            paddingLeft: theme.mediumSpace,
            paddingRight: theme.mediumSmallSpace,
            paddingTop: theme.largeSpace * 2,
            opacity: sideMenuOpacity,
            backgroundColor: smallScreen ? theme.sideMenuColor : undefined,
            height: "100vh",
            position: "absolute",
            left: 0,
            top: 0,
            transform: [{ translateX: sideMenuLeft }],
        } }, { children: [_jsx(TouchableOpacity, __assign({ onPress: navigate("Home") }, { children: _jsx(StyledText, __assign({ type: "body" }, { children: "Home" })) })), _jsx(Padding, { vertical: theme.mediumSmallSpace }), _jsx(TouchableOpacity, __assign({ onPress: navigate("Work") }, { children: _jsx(StyledText, __assign({ type: "body" }, { children: "Work" })) })), _jsx(Padding, { vertical: theme.mediumSmallSpace }), _jsx(TouchableOpacity, __assign({ onPress: navigate("Apps") }, { children: _jsx(StyledText, __assign({ type: "body" }, { children: "Apps" })) })), _jsx(Padding, { vertical: theme.mediumSmallSpace }), _jsx(TouchableOpacity, __assign({ onPress: navigate("NonApps") }, { children: _jsx(StyledText, __assign({ type: "body" }, { children: "NonApps" })) })), _jsx(Padding, { vertical: theme.mediumSmallSpace }), _jsx("a", __assign({ href: "mailto:bmbmjmdm@gmail.com" }, { children: _jsx(StyledText, __assign({ type: "body" }, { children: "Contact" })) }))] })));
};
// @ts-ignore-next-line
export var SideMenu = forwardRef(SideMenuComponent);
